import { useEffect, useState } from "react";
import Image from "next/image";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Moment from "../moment";
// apollo
import { client } from '../../../../lib/apollo';
import { gql } from "@apollo/client";

const filters = [
  {
    id: 1,
    slug: "hoa-hau",
    name: "Hoa hậu",
  },
  {
    id: 2,
    slug: "life-style",
    name: "Life Style",
  },
  {
    id: 3,
    slug: "giai-tri",
    name: "Giải trí",
  },
  // {
  //   id: 4,
  //   slug: "short-clip",
  //   name: "Short Clip",
  // },
];

const defaultActiveCat = filters[0].slug;

const PostSectionSeven = ({ categoryData, currentCategory }) => {

  const defaultData = categoryData.props.categories.filter(
    (category) => category.slug === currentCategory
  );

  const [activeNav, setActiveNav] = useState(defaultActiveCat);
  const [tabPostData, setTabPostData] = useState(defaultData);
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    if (tabPostData[0]?.categoryId) {
      const getNewPosts = async () => {
        const GET_CATEGORY_POSTS = gql`
          query GetCategoryPosts {
            posts(where: {orderby: {field: DATE, order: DESC}, taxQuery: {taxArray: [{taxonomy: CATEGORY, field: SLUG, includeChildren: false, operator: EXISTS}]}, categoryId: ${tabPostData[0]?.categoryId}}, first: 5) {
              nodes {
                postId
                date
                slug
                title
                categories {
                  nodes {
                    slug
                  }
                }
                featuredImage {
                  node {
                    altText
                    sourceUrl
                  }
                }
                seo {
                  opengraphDescription
                }
              }
            }
            ${currentCategory === "short-clip" ?
            `mediaItems(where: {orderby: {field: DATE, order: DESC}, categoryId: ${tabPostData[0]?.categoryId}}, first: 4) {
                  nodes {
                    id
                    mediaItemUrl
                  }
                }` : ""
          }
          }
        `;
        const response = await client.query({
          query: GET_CATEGORY_POSTS
        });
        const posts = response?.data?.posts?.nodes;
        const medias = response?.data?.mediaItems?.nodes;
        setPostData(posts);
      }
      getNewPosts();
    }
  }, [tabPostData]);

  const handleChange = (slug) => {
    setActiveNav(slug);
    const newTabPostData = categoryData.props.categories.filter(
      (category) => category.slug === slug
    );
    setTabPostData(newTabPostData);
  };

  return (
    <div className="axil-post-grid-area axil-section-gapTop">
      <Tab.Container id="axilTab" defaultActiveKey={activeNav} >
        <Nav className="position-relative">
          <Nav.Item className="axil-single-widget widget widget_postlist position-relative" style={{ background: 'white', zIndex: 1 }}>
            <Nav.Link
              className="p--0"
              onClick={() => handleChange(defaultData[0].slug)}
              eventKey={defaultData[0]?.slug}
              style={{ cursor: "pointer" }}
            >
              <h3 className="widget-title">{defaultData[0]?.name.toUpperCase()}</h3>
            </Nav.Link>
          </Nav.Item>
          {defaultData[0]?.children?.nodes.slice(0, 3).map((data, index) => (
            <Nav.Item key={index} className="position-relative" style={{ background: 'white', zIndex: 1 }}>
              <Nav.Link
                onClick={() => handleChange(data.slug)}
                eventKey={data.slug}
                className="pr--0"
              >
                <span className={`axil-cate-button ${tabPostData && tabPostData[0]?.slug === data.slug ? "active" : ""}`} style={{ cursor: "pointer" }}>
                  {data.name}
                </span>
              </Nav.Link>
            </Nav.Item>
          ))}
          <Nav.Item className="position-absolute d-none d-sm-block" style={{ width: '100%', top: '33%', left: 0, border: 'none', borderTop: '1px solid #ddd9d9' }}>
            <Nav.Link className="p--0" eventKey="hr">
              <hr />
            </Nav.Link>
          </Nav.Item>
          <div className="d-none d-sm-block pl--10" style={{ position: 'absolute', top: 6, right: 0, background: 'white', zIndex: 1 }}>
            <a href={`/chuyen-muc/${defaultData[0]?.slug}`} aria-label={defaultData[0]?.name}>
              <span className={`axil-cate-button active`} style={{ cursor: "pointer", fontSize: 12 }}>
                Xem tất cả
              </span>
            </a>
          </div>
        </Nav>

        <a href={`/chuyen-muc/${defaultData[0]?.slug}`} aria-label={defaultData[0]?.name} >
          <span className={`axil-cate-button active d-block d-sm-none`} style={{ cursor: "pointer", fontSize: 12, position: "relative", top: -13, zIndex: 1 }}>
            Xem tất cả
          </span>
        </a>

        <Tab.Content className="grid-tab-content">
          <Tab.Pane className="single-post-grid" eventKey={activeNav}>
            <div className="row">
              {
                postData[0] ? <>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="active show content-block post-grid post-grid-large">
                      {postData[0].featuredImage ?
                        <div className="post-thumbnail">
                          <a href={`/${postData[0].categories.nodes[0].slug}/${postData[0].slug}`} aria-label={postData[0].title} className="position-relative" style={{ height: 240 }}>
                            <Image
                              src={postData[0].featuredImage.node.sourceUrl}
                              alt={postData[0].featuredImage.node.altText ? postData[0].featuredImage.node.altText : "Thumbnail"}
                              fill
                              style={{ objectFit: "cover" }}
                              sizes="100%"
                              priority={true}
                              placeholder="blur"
                              blurDataURL="images/placeholder.png"
                            />
                          </a>
                        </div> : ""
                      }
                      <ul className="post-meta-list m--0 d-none d-sm-block">
                        <li className="d-flex align-items-center ml--0">
                          <Moment date={postData[0].date} />
                        </li>
                      </ul>
                      <div className="post-content mt_sm--10">
                        <h4 className="title">
                          <a href={`/${postData[0].categories.nodes[0].slug}/${postData[0].slug}`} aria-label={postData[0].title}>{postData[0].title}</a>
                        </h4>
                      </div>
                      <ul className="post-meta-list m--0 d-block d-sm-none">
                        <li className="d-flex align-items-center ml--0">
                          <Moment date={postData[0].date} />
                        </li>
                      </ul>
                      <p className="b3 content mt--5">
                        {postData[0].seo.opengraphDescription}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-none d-sm-block">
                    <div className="row no-overflow" style={{ height: '100%' }}>
                      {
                        postData.slice(1, 5).map((data) => (
                          <div
                            className="col-xl-6 col-lg-6 col-md-6 col-12 mt_sm--30"
                            style={{ height: 220 }}
                            key={data.slug}
                          >
                            <div className="content-block post-grid d-none d-md-block">
                              {data.featuredImage ?
                                <div className="post-thumbnail-sub">
                                  <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title} className="position-relative">
                                    <Image
                                      src={data.featuredImage.node.sourceUrl}
                                      alt={data.featuredImage.node.altText ? data.featuredImage.node.altText : "Thumbnail"}
                                      fill
                                      style={{ objectFit: "cover" }}
                                      sizes="100%"
                                      priority={true}
                                      placeholder="blur"
                                      blurDataURL="images/placeholder.png"
                                    />
                                  </a>
                                </div>
                                : ""}

                              <ul className="post-meta-list m--0">
                                <li className="d-flex align-items-center ml--0">
                                  <Moment date={data.date} />
                                </li>
                              </ul>
                              <div className="post-grid-content">
                                <div className="post-content">
                                  <h5 className="title" style={{ fontSize: 16 }}>
                                    <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>{data.title}</a>
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="content-block hot-list-view axil-control d-flex d-md-none">
                              {data.featuredImage ?
                                <div className="post-thumbnail">
                                  <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title} className="position-relative">
                                    <Image
                                      src={data.featuredImage.node.sourceUrl}
                                      alt={data.featuredImage.node.alt ? data.featuredImage.node.altText : "Thumbnail"}
                                      fill
                                      style={{ objectFit: "cover" }}
                                      sizes="100%"
                                      priority={true}
                                      placeholder="blur"
                                      blurDataURL="images/placeholder.png"
                                    />
                                  </a>
                                </div>
                                : ""}

                              <div className="post-content">
                                <ul className="post-meta-list m--0">
                                  <li className="d-flex align-items-center ml--0 mt--0">
                                    <Moment date={data.date} />
                                  </li>
                                </ul>
                                <h5 className="title" style={{ fontSize: 16 }}>
                                  <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>{data.title}</a>
                                </h5>
                                <div >
                                  <p className="b2 content">
                                    {data.seo.opengraphDescription}
                                  </p>
                                </div>
                              </div>
                            </div>

                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="row no-overflow" style={{ height: '100%' }}>
                      {
                        postData.slice(1, 5).map((data) => (
                          <div
                            className="col-xl-6 col-lg-6 col-md-6 col-12"
                            key={data.slug}
                          >
                            <div className="d-block d-sm-none mt--15">
                              <div className="content-block hot-list-view axil-control border--top pt--15">
                                <div className="post-content pt--0 pl--0">
                                  <h5 className="title">
                                    <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title}>{data.title}</a>
                                  </h5>
                                  <ul className="post-meta-list">
                                    <li className="d-flex align-items-center li-more">
                                      <Moment date={data.date} />
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="content-block hot-list-view axil-control"
                                  key={data.slug}
                                >
                                  {data.featuredImage ?
                                    <div className="post-thumbnail">
                                      <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title} className="position-relative">
                                        <Image
                                          src={data.featuredImage.node.sourceUrl}
                                          alt={data.featuredImage.node.alt ? data.featuredImage.node.altText : "Thumbnail"}
                                          fill
                                          sizes="100%"
                                          priority={true}
                                          placeholder="blur"
                                          blurDataURL="images/placeholder.png"
                                        />
                                      </a>
                                    </div>
                                    : ""}

                                  <div className="post-content" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                    <div className="content-block">
                                      <div className="post-content">
                                        <p className="b2 content">
                                          {data.seo.opengraphDescription}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </> : ""
              }
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default PostSectionSeven;
