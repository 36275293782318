import Image from "next/image";
import { SectionTitleTwo } from "../../elements/sectionTitle/SectionTitle";
import Slider from "react-slick";

const TagListSlide = ({ tagData }) => {

  //remove tag with no image
  const tags = tagData.props.tags.filter((tag) => tag.imageUrl !== null);

  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={`slide-arrow next-arrow ${className}`}
        onClick={onClick}
        aria-label="Next"
      >
        <i className="fas fa-arrow-right"></i>
      </button>
    );
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={`slide-arrow prev-arrow ${className}`}
        onClick={onClick}
        aria-label="Previous"
      >
        <i className="fas fa-arrow-left"></i>
      </button>
    );
  }

  const slideSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: true,
    arrows: true,
    dots: false,
    centerMode: true,
    initialSlide: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      {
        tags.length > 0 && <>
          <div className="d-none d-sm-block mb--30">
            <div className="axil-categories-list axil-section-gap bg-color-gradient rounded-4 pl--15 pr--15">
              <SectionTitleTwo
                title="CHỦ ĐỀ HOT"
              // btnText="Xem tất cả chủ đề" 
              />
              <div className="row">
                <div className="col-lg-12">
                  <Slider {...slideSettings} className="list-categories categories-activation axil-slick-arrow arrow-between-side">
                    {tags.map((data, index) => (
                      data.imageUrl &&
                      <div className="single-cat" key={index}>
                        <div className="inner">
                          <a href={`/tag/${data.slug}`} aria-label={data.name}>
                            <div className="thumbnail">
                              <Image
                                src={data.imageUrl}
                                alt={data.name ? data.name : " Thumbnails"}
                                height={300}
                                width={190}
                                style={{ objectFit: "cover", width: 180, height: 250 }}
                                placeholder="blur"
                                blurDataURL="images/placeholder.png"
                              />
                              <div className="overlay-image"></div>
                            </div>
                            <div className="hot-tag">
                              <div className="default">
                                <div className="content">
                                  {
                                    data.posts.nodes[0]?.categories.nodes.length > 0 &&
                                    <div className="post-cat">
                                      <div className="post-cat-list">
                                        <span data-text={data.posts.nodes[0].categories.nodes[0].name}>
                                          {data.posts.nodes[0].categories.nodes[0].name}
                                        </span>
                                      </div>
                                    </div>
                                  }
                                  <h5 className="title">#{data.name}</h5>
                                  <p className="has-very-light-gray-color b4">{data.tagViewsCount} người quan tâm</p>
                                </div>
                              </div>
                              <div className="hover">
                                <div className="content">
                                  <h5 className="title">#{data.name}</h5>
                                  <p className="has-very-light-gray-color b4 mb--0"> Có {data.count} bài viết</p>
                                  <button type="submit" className="explore-button" aria-label="Search">
                                    Khám phá ngay
                                  </button>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}

                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block d-sm-none mb--15">
            <div className="axil-categories-list axil-section-gap bg-color-gradient pl--15 pt--20">
              <SectionTitleTwo
                title="CHỦ ĐỀ HOT"
              // btnText="Xem tất cả chủ đề" 
              />
              <div className="row">
                <div className="col-lg-12">
                  <Slider {...slideSettings} className="list-categories categories-activation axil-slick-arrow arrow-between-side">
                    {tags.map((data, index) => (
                      data.imageUrl &&
                      <div className="single-cat" key={index}>
                        <div className="inner">
                          <a href={`/tag/${data.slug}`} aria-label={data.name}>
                            {
                              data.imageUrl &&
                              <div className="thumbnail">
                                <Image
                                  src={data.imageUrl}
                                  alt={data.name ? data.name : "Thumbnail"}
                                  height={350}
                                  width={190}
                                  style={{ objectFit: "cover", width: 180, height: 280 }}
                                  placeholder="blur"
                                  blurDataURL="images/placeholder.png"
                                />
                                <div className="overlay-image"></div>
                              </div>
                            }
                            <div className="hot-tag">
                              <div className="default">
                                <div className="content">
                                  {
                                    data.posts.nodes[0]?.categories.nodes.length > 0 &&
                                    <div className="post-cat">
                                      <div className="post-cat-list">
                                        <span data-text={data.posts.nodes[0].categories.nodes[0].name}>
                                          {data.posts.nodes[0].categories.nodes[0].name}
                                        </span>
                                      </div>
                                    </div>
                                  }
                                  <h5 className="title">#{data.name}</h5>
                                  <p className="has-very-light-gray-color b4">{data.tagViewsCount} người quan tâm</p>
                                </div>
                              </div>
                              <div className="hover">
                                <div className="content">
                                  <h5 className="title">#{data.name}</h5>
                                  <p className="has-very-light-gray-color b4 mb--0"> Có {data.count} bài viết</p>
                                  <button type="submit" className="explore-button" aria-label="Search">
                                    Khám phá ngay
                                  </button>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
                  </Slider >
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default TagListSlide;
