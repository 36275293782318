import Image from "next/image";
import Skeleton from 'react-loading-skeleton'
import Moment from "../moment";

const PostSectionThirteen = ({ postData, pClass }) => {

  const posts = postData.props.posts;
  const firstPost = posts[0];

  return (
    <div className={`axil-post-grid-area bg-color-white mb--30 ${pClass ? pClass : ""}`}>
      <div className="content-block post-new-view mb--15">
        {firstPost.featuredImage ? (
          <div className="post-thumbnail">
            <a href={`/${firstPost.categories.nodes[0].slug}/${firstPost.slug}`} aria-label={firstPost.title} className="position-relative">
              <Image
                src={firstPost.featuredImage.node.sourceUrl}
                alt={firstPost.featuredImage.node.altText ? firstPost.featuredImage.node.altText : "Thumbnail"}
                fill
                sizes="100%"
                priority={true}
                placeholder="blur"
                blurDataURL="images/placeholder.png"
              />
            </a>
          </div>
        ) :
          <div className="post-thumbnail">
            <div style={{ height: '100%' }} >
              <Skeleton style={{ height: '100%' }} />
            </div>
          </div>
        }
        <div className="post-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          {
            firstPost.categories.nodes[0].slug && firstPost.slug && firstPost.title ?
              <h1 className="title" style={{ fontWeight: 600, fontSize: 26 }}>
                <a href={`/${firstPost.categories.nodes[0].slug}/${firstPost.slug}`} aria-label={firstPost.title} style={{ transition: 'auto' }}>{firstPost.title}</a>
              </h1>
              :
              <h3 className="title">
                <Skeleton count={3} />
              </h3>
          }
          <ul className="post-meta-list">
            {
              firstPost.categories.nodes.length > 0 ?
                <li>
                  <div className="post-cat">
                    <div className="post-cat-list">
                      <a href={`/chuyen-muc/${firstPost.categories.nodes[0].slug}`} aria-label={firstPost.categories.nodes[0].name}>
                        <span data-text={firstPost.categories.nodes[0].name}>
                          {firstPost.categories.nodes[0].name}
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                :
                <li>
                  <div className="post-cat">
                    <div className="post-cat-list">
                      <a aria-label="skeleton">
                        <span data-text="skeleton">
                          <Skeleton count={1} width={75} height={20} />
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
            }
            <li className="d-flex align-items-center">
              {
                firstPost.date ? <Moment date={firstPost.date} /> : <Skeleton count={1} width={75} height={20} />
              }
            </li>
          </ul>
          <p className="b2 content">
            {firstPost.seo.opengraphDescription ? firstPost.seo.opengraphDescription : <Skeleton count={3} />}
          </p>
        </div>
      </div>
      <div className="row new">
        {posts.slice(1, 4).map((data) => (
          <div className="col-lg-4 col-md-4 col-sm-4 col-12" key={data.slug}>
            <div className="content-block post-default image-rounded">
              {data.featuredImage ?
                <div className="post-thumbnail">
                  <a href={`/${data.categories.nodes[0].slug}/${data.slug}`} aria-label={data.title} className="position-relative">
                    <Image
                      src={data.featuredImage.node.sourceUrl}
                      alt={data.featuredImage.node.altText ? data.featuredImage.node.altText : "Thumbnail"}
                      height={155}
                      width={210}
                      priority={true}
                      style={{ maxHeight: 109 }}
                      placeholder="blur"
                      blurDataURL="images/placeholder.png"
                    />
                  </a>
                </div>
                : <div className="post-thumbnail">
                  <Skeleton height={108} />
                </div>
              }
              <div className="post-content">
                <ul className="post-meta-list">
                  {
                    data.categories.nodes.length > 0 ?
                      <li>
                        <div className="post-cat">
                          <div className="post-cat-list">
                            <a href={`/chuyen-muc/${data.categories.nodes[0].slug}`} className="hover-flip-item-wrapper" aria-label={data.categories.nodes[0].name}>
                              <span data-text={data.categories.nodes[0].name}>
                                {data.categories.nodes[0].name}
                              </span>
                            </a>
                          </div>
                        </div>
                      </li>
                      :
                      <li>
                        <div className="post-cat">
                          <div className="post-cat-list">
                            <a aria-label="skeleton">
                              <span data-text="skeleton">
                                <Skeleton count={1} width={75} height={20} />
                              </span>
                            </a>
                          </div>
                        </div>
                      </li>
                  }
                  <li className="d-flex align-items-center">
                    {data.date ? <Moment date={data.date} /> : <Skeleton count={1} width={75} height={20} />}
                  </li>
                </ul>
                <h5 className="title" style={{ marginTop: -5 }}>
                  {
                    data.categories.nodes[0].slug && data.slug && data.title ?
                      <a href={`/${data.categories?.nodes[0]?.slug}/${data.slug}`} aria-label={data.title} style={{ transition: 'auto' }}>{data.title}</a>
                      : <Skeleton count={3} />
                  }
                </h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostSectionThirteen;
